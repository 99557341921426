<!-- 任务转交弹窗 -->
<template>
  <el-dialog
      width="30%"
      center
      :visible="visible"
      :lock-scroll="false"
      :destroy-on-close="true"
      custom-class="ele-dialog-form"
      :title="isUpdate?'任务转交':'任务转交'"
      @update:visible="updateVisible">
    <el-form
        ref="form"
        :model="form"
        label-width="80px">

      <div style="margin-top: 10px;margin-bottom: 10px;">
        <span style="font-size: 16px;font-weight: 500;color: #242832;">请选择转交给谁？</span>
      </div>

      <div style="margin-bottom: 20px;">
        <el-select
            clearable
            v-model="form.transfer_id"
            placeholder="请选择"
            class="ele-fluid">
          <el-option v-for="(item,index) in List" :label="item.realname" :value="parseInt(item.id)"/>
        </el-select>
      </div>

    </el-form>
    <div slot="footer">
      <el-button
          @click="updateVisible(false)">取消
      </el-button>
      <el-button
          style="margin-left: 20px;"
          type="primary"
          :loading="loading"
          @click="save">确定
      </el-button>
    </div>
  </el-dialog>
</template>

<script>

import {get_staff_list, transfer_to} from "@/api/orderModule";

export default {
  name: 'UserEdit',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object,
    selection: Array
  },
  components: { },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,

      List:[],

      ids:[]
    };
  },
  watch: {
    data() {

    }
  },
  mounted() {
    if (this.data) {
      console.log(this.data)
      this.form = Object.assign({}, this.data);
      this.isUpdate = true;
    } else {
      this.form = {};
      this.isUpdate = false;
      // 批量转交数据
      let ids = [];
      this.selection.forEach(item => {
        ids.push(item.id)
      })
      this.ids =  ids;
      console.log(this.ids)
    }

    // 获取员工接口
    this.getList();

  },
  methods: {
    // 获取同部门员工列表接口
    getList(){
      get_staff_list().then(res => {
        console.log(res)
        this.List = res.data
      }).catch(err => {
        console.log(err)
      })
    },


    /* 保存编辑 */
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if(this.form.transfer_id == undefined || this.form.transfer_id == null || this.form.transfer_id == ''){
            this.$message.warning('请选择转交对象');
          }else {
            // 单个转交
            if(this.isUpdate == true){
              // 任务转交
              let data = {
                transfer_id: this.form.transfer_id,
                ids: [this.data.id]
              }
              transfer_to(data).then(res => {
                console.log(res)
                if (res.code == 200) {
                  this.loading = true;
                  this.updateVisible(false);
                  this.$emit('done');
                  this.loading = false;
                  this.$message.success('任务转交成功');
                } else {
                  this.$message.error(res.msg);
                }
              }).catch(err => {
                console.log(err)
                this.$message.error(err.msg);
              })
            }else {
              // 批量转交
              let data = {
                transfer_id: this.form.transfer_id,
                ids: this.ids
              }
              transfer_to(data).then(res => {
                console.log(res)
                if (res.code == 200) {
                  this.loading = true;
                  this.updateVisible(false);
                  this.$emit('done');
                  this.loading = false;
                  this.$message.success('任务转交成功');
                } else {
                  this.$message.error(res.msg);
                }
              }).catch(err => {
                console.log(err)
                this.$message.error(err.msg);
              })
            }
          }




        } else {
          return false;
        }
      });
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

  }
}
</script>

<style scoped lang="scss">

</style>
